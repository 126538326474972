import { useEffect, useState } from "react";
import { Button, FormControl, Spinner, Table, Modal, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import http from "../utils/Api";
import { getAllPlayersKyc } from "../service/actions/KycAction";

const KYCList = ({ userKYC }) => {
  console.log(userKYC,'asd')
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [rejectType, setRejectType] = useState("");
  const [documentFiles, setDocumentFiles] = useState({});
  const [documentLoading, setDocumentLoading] = useState(false); // New state for document loading

  const dispatch = useDispatch();

  useEffect(() => {
    if (userKYC.length > 0) {
      setLoading(false);
    }
  }, [userKYC]);

  const offset = currentPage * itemsPerPage;
  const currentUsers = userKYC.slice(offset, offset + itemsPerPage);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };
  const pageCount = Math.ceil(userKYC.length / itemsPerPage);

  const updateKYCStatus = async (status, reason = "") => {
    const accessToken = localStorage.getItem("accessToken");

    const payload = {
      documentStatus: status,
      videoStatus: status,
      reason: status === 'rejected' ? reason : null,
    };

    try {
      await http.put(
        `/api/admin/updatePlayerKycStatus/${selectedUser.id}`,
        payload,
        http.generateConfig(accessToken)
      );
      dispatch(getAllPlayersKyc());
      console.log(`${status === 'verified' ? 'Approved' : 'Rejected'} KYC for user ${selectedUser.id}`);
    } catch (error) {
      console.error('Error updating KYC status:', error);
    }
  };

  const handleApprove = (type) => {
    if (type === 'document') {
      updateKYCStatus('verified');
    } else if (type === 'video') {
      updateKYCStatus('verified');
    }
    setShowDocumentModal(false);  // Close modal after approval
  };

  const handleRejectSubmit = () => {
    if (rejectType === 'document') {
      updateKYCStatus('rejected', rejectReason);
    } else if (rejectType === 'video') {
      updateKYCStatus('rejected', rejectReason);
    }
    setShowRejectModal(false);
  };

  const openDocumentModal = async (user) => {
    setSelectedUser(user);
    setDocumentLoading(true); // Start loading

    let videoFile = null;
    let frontDoc = null;
    let backDoc = null;

    try {
      // Fetch the video
      if (user.selfieVideo) {
        const videoBlob = await getDocuments(user.selfieVideo, 'Player-Kyc-Selfie-Video', 'video');
        console.log(videoBlob,'1TE AOSDNASDA')
        videoFile = URL.createObjectURL(videoBlob);  // Create Object URL for video blob
        console.log(videoFile,'2TE AOSDNASDA')
      }


      // Fetch the front document
      if (user.identityDocumentFront) {
        const frontBlob = await getDocuments(user.identityDocumentFront, 'Player-Kyc-Document', 'image');
        frontDoc = URL.createObjectURL(frontBlob);  // Create Object URL for front document blob
      }

      // Fetch the back document
      if (user.identityDocumentBack) {
        const backBlob = await getDocuments(user.identityDocumentBack, 'Player-Kyc-Document', 'image');
        backDoc = URL.createObjectURL(backBlob);  // Create Object URL for back document blob
      }

      // Set the document files to state
      setDocumentFiles({
        videoFile,
        frontDoc,
        backDoc
      });

      // Show the modal
      setShowDocumentModal(true);
    } catch (error) {
      console.error("Error fetching documents: ", error);
    } finally {
      setDocumentLoading(false); // Stop loading
    }
  };

  const getDocuments = async (fileName, folderName, type) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const config = {
        ...http.generateConfig(accessToken),
        responseType: 'blob',  // Expecting the response to be binary (Blob)
      };

      const res = await http.get(`/api/admin/getKycDocument/${type}/${fileName}`, config);
      return res;
    } catch (error) {
      console.log('Error in getDocuments:', error);
    }
  };

  const handleRejectClick = (type) => {
    setRejectType(type);
    setShowRejectModal(true);
  };

  return (
    <div className="game-list">
      {/* Your existing code for rendering table and pagination */}
      <div className="form-content-adduser container-fluid">
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <div>
            <h3>Players</h3>
            <div className="pagination-parent container-fluid">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousClassName={"previous"}
                nextClassName={"next"}
                activeClassName={"active"}
                activeLinkClassName={"active"}
                previousLinkClassName={"previous"}
                nextLinkClassName={"next"}
                disabledClassName={"disabled"}
              />
            </div>

            <FormControl
              type="text"
              placeholder="Search by user email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Doc status</th>
                  <th>Video status</th>
                  {/* <th>expiry</th> */}
                  <th>Uploads</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.Player.id}</td>
                    <td>{user.Player.email || '-'}</td>
                    <td>{user.Player.mobile || '-'}</td>
                    <td>{user.documentStatus}</td>
                    <td>{user.videoStatus}</td>
                    {/* <td></td> */}
                    <td>
                      <Button variant="success" onClick={() => openDocumentModal(user)}>
                        View Documents
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      {/* View Document Modal */}
      <Modal show={showDocumentModal} onHide={() => setShowDocumentModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {documentLoading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading documents...</span>
            </Spinner>
          ) : selectedUser ? (
            <div className="document-grid">
              {documentFiles.videoFile ? (
                <div>
                  <video width="100%" controls>
                    <source src={documentFiles.videoFile} />
                    Your browser does not support the video tag.
                  </video>
                  <Button variant="success" onClick={() => handleApprove('video')} style={{ margin: '10px' }}>
                    Approve Video
                  </Button>
                  <Button variant="danger" onClick={() => handleRejectClick('video')} style={{ margin: '10px' }}>
                    Reject Video
                  </Button>
                </div>
              ) : (
                <p>No video available</p>
              )}

              <hr />
              
              <Button
                    variant="outline-primary"
                    style={{ margin: '10px' }}
                    disabled
                  >
                    Document Type: {selectedUser?.identityDocumentType}
                  </Button>
              {documentFiles.frontDoc && (
                <div>
                  <Button
                    variant="outline-primary"
                    onClick={() => window.open(documentFiles.frontDoc, '_blank')}
                    style={{ margin: '10px' }}
                    disabled
                  >
                    Identity Document (Front)
                  </Button>
                  <img src={documentFiles.frontDoc} alt="Identity Document (Front)" />
                  
                </div>
              )}

              {documentFiles.backDoc && (
                <div>
                  <Button
                    variant="outline-primary"
                    onClick={() => window.open(documentFiles.backDoc, '_blank')}
                    style={{ margin: '10px' }}
                    disabled
                  >
                    Identity Document (Back)
                  </Button>
                  <img src={documentFiles.backDoc} alt="Identity Document (Back)" />
                </div>
              )}

              <Button variant="success" onClick={() => handleApprove('document')} style={{ margin: '10px' }}>
                Approve Documents
              </Button>
              <Button variant="danger" onClick={() => handleRejectClick('document')} style={{ margin: '10px' }}>
                Reject Documents
              </Button>
            </div>
          ) : (
            <p>No documents to display</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDocumentModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Reject Modal */}
      <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reject KYC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="rejectReason">
              <Form.Label>Reason for Rejection</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter reason"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRejectSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default KYCList;
